<template>
  <div class="statistics-box" v-if="statisticsList && statisticsList.length">
    <div class="statistics-box-view" v-for="item,index in statisticsList" :key="index">
      <div class="statistics-box-view-num">{{ item.value }} {{ item.unit }}</div>
      <div class="statistics-box-view-text">{{ item.name }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    statisticsList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  watch: {
    statisticsList: {
      handler(v) {
        console.log(v);
      }
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
  .statistics-box {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    margin-top: 10px;
    padding: 0 12px;
    background-color: #fff;
    border-radius: 8px;
    &-view {
    min-width: 200px;
      margin: 12px;
      text-align: center;
      &-num{
        font-size: 24px;
        font-weight: bold;
        color: #4278C9;
        margin-bottom: 8px;
      }
      &-text{
        font-size: 14px;
        color: #333333;
      }
    }
  }
</style>