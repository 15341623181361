<template>
  <div>
    <GeneralTable ref="GeneralTable"
      :column="column"
      :option="option"
      :dataList="dataList"
      @search-change="handleSearch"
      @export-data="handleExport"
      :totalNum="totalNum"
      tableName="RenewalManagement">
      <!-- 统计插槽 -->
      <template slot="statistics">
        <statistics-box :statisticsList="statisticsList"
          v-loading="statisticsLoading" />
      </template>

      <template slot="operate"
        slot-scope="{ item }">
        <el-button type="text"
          v-if="item.renewalState !== 1 && handleHasPerms(`M24-Registration_${ $route.meta.policyType }`)"
          @click="handleCheck(item, 1)">续保登记</el-button>
        <span v-else>-</span>
      </template>
      <template slot="renewalState"
        slot-scope="{ item }">
        <span type="text">{{ item.renewalState === 1 ? "是" : "否" }}</span>
      </template>
      <template slot="newEnquiryNo"
        slot-scope="{ item }">
        <div class="el-button polecy-button el-button--text el-button--small"
          style="text-decoration: underline; padding: 0"
          @click="handleCheck(item, 2)">
          {{ item.newEnquiryNo }}
        </div>
      </template>
      <template slot="frameNo"
        slot-scope="{ item }">
        {{ item.isSingle !== false ? item.frameNo : '多标的类型，详细查看详情' }}
      </template>
      <template slot="policyNo"
        slot-scope="{ item }">
        <div class="el-button polecy-button el-button--text el-button--small"
          style="text-decoration: underline; padding: 0"
          @click="navToPolicy(item)">
          {{ item.policyNo }}
        </div>
      </template>
      <template slot="lastPolicyNo"
        slot-scope="{ item }">
        <div class="el-button polecy-button el-button--text el-button--small"
          style="text-decoration: underline; padding: 0"
          @click="navToPolicy(item)">
          {{ item.lastPolicyNo }}
        </div>
      </template>
    </GeneralTable>
  </div>
</template>
<script>
import { mapState } from "vuex";
import GeneralTable from "@/components/GeneralTable/index.vue";
import StatisticsBox from "@/components/StatisticsBox/index.vue";
import { hasPerms, dataPermissions } from "@/utils/auth";
import {
  renewalList,
  exportRenewalByIds,
  renewalListFast,
  dictionaryBatch,
  getRenewalDataStatistics
} from "@/api/policy.js";
import { pageListToSelect } from "@/api/basicData.js";
export default {
  name: 'renewalManagement1',
  components: {
    GeneralTable,
    StatisticsBox
  },
  data() {
    return {
      totalNum: 0,
      option: {
        isSearch: true, // 搜索 @search-change
        isRefresh: true, // 刷新 @search-change
        isExport: hasPerms(`M24-export_${ this.$route.meta.policyType }`), // 刷新 @search-change
        pageSize: 10,
        tableHeight: 'calc(100vh - 122px)', 
        rowPlaceholder: '--',
        searchList: [
          {
            label: "上一年单号",
            prop: "lastPolicyNo",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入上一年单号",
            isPermissions: dataPermissions(`M24_${ this.$route.meta.policyType }-View`, "lastPolicyNo"),
          },
          {
            label: "投保人",
            prop: "playersHolder",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入投保人",
            isPermissions: dataPermissions(`M24_${ this.$route.meta.policyType }-View`, "policyHolder"),
          },
          {
            label: "被保险人",
            prop: "insured",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入被保险人",
            isPermissions: dataPermissions(`M24_${ this.$route.meta.policyType }-View`, "insured"),
          },
          {
            label: "险种类型",
            prop: "insuredCategory",
            clearable: true,
            placeholder: "请选择",
            formType: "select",
            filterable: true,
            selectList: [],
            isPermissions: this.$route.meta.policyType != 1 && dataPermissions(`M24_${ this.$route.meta.policyType }-View`, "category"),
          },
          {
            label: "险种名称",
            prop: "insuranceName",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入",
            isPermissions: this.$route.meta.policyType == 1 && dataPermissions(`M24_${ this.$route.meta.policyType }-View`, "insuranceName"),
          },
          {
            label: "项目名称",
            prop: "projectNameList",
            formType: "cascader",
            formProps: {
              multiple: true,
              checkStrictly: true,
              emitPath: false,
              value: 'projectName',
              label: 'projectName',
              children: 'subProjectList'
            },
            collapseTags: true,
            clearable: true,
            // filterable: true,
            showAllLevels: false,
            placeholder: "请选择",
            selectList: [],
            isPermissions: this.$route.meta.policyType != 2 && dataPermissions(`M24_${ this.$route.meta.policyType }-View`, "projectName"),
          },
          {
            label: "业务渠道",
            prop: "sourceChannelVal",
            formType: "select",
            filterable: true,
            placeholder: "请选择",
            selectList: [],
            isPermissions: dataPermissions(`M24_${ this.$route.meta.policyType }-View`, "sourceChannel"),
          },

          //  {
          //    label: "承保方案名称",
          //    prop: "schemeName",
          //    formType: "input",
          //    maxLength: 36,
          //    clearable: true,
          //    placeholder: "请输入承保方案名称",
          //  },

          {
            label: "保险公司",
            prop: "insuranceCompanyName",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入保险公司",
            // formType: "select",
            // filterable: true,
            // selectList: [
            //   { value: "", text: "全部" },
            //   { value: "1", text: "平安保险" },
            //   { value: "2", text: "人寿保险" },
            //   { value: "3", text: "人地保险" },
            //   { value: "4", text: "中国平安保险（集团）股份有限公司" },
            // ],
            isPermissions: dataPermissions(`M24_${ this.$route.meta.policyType }-View`, "insuranceCompany"),
          },
          {
            label: "分支机构",
            prop: "branchName",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入分支机构",
            isPermissions: dataPermissions(`M24_${ this.$route.meta.policyType }-View`, "insuranceBranchName"),
          },
          {
            label: "设备类型",
            prop: "deviceTypeName",
            formType: "select",
            filterable: true,
            selectList: [],
            placeholder: "请选择",
            isPermissions: dataPermissions(`M24_${ this.$route.meta.policyType }-View`, "insuranceBranchName") && this.$route.meta.policyType != 3,
          },
          {
            label: "保单状态",
            prop: "policyState",
            formType: "select",
            filterable: true,
            selectList: [
              { value: "1", text: "未起保" },
              { value: "2", text: "保障中" },
              { value: "3", text: "已过期" },
            ],
            isPermissions: dataPermissions(`M24_${ this.$route.meta.policyType }-View`, "insuranceBranchName"),
          },
          {
            label: "保险到期",
            formType: "daterange",
            format: 'yyyy.MM.dd',
            clearable: true,
            prop: ["endDateBegin", "endDateEnd"],
            isPermissions: dataPermissions(`M24_${ this.$route.meta.policyType }-View`, "endDate"),
          },
          {
            label: "续保状态",
            prop: "renewalState",
            formType: "select",
            filterable: true,
            selectList: [
              { value: "1", text: "是" },
              { value: "2", text: "否" },
            ],
            isPermissions: dataPermissions(`M24_${ this.$route.meta.policyType }-View`, "renewalState"),
          },
          {
            label: "业务员",
            prop: "business",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入业务员",
            isPermissions: dataPermissions(`M24_${ this.$route.meta.policyType }-View`, "salesmanName"),
          },
          {
            label: "所属部门",
            prop: "deptName",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入",
            isPermissions: dataPermissions(`M24_${ this.$route.meta.policyType }-View`, "deptName"),
          },
          {
            label: "新保单号",
            prop: "newPolicyNo",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入",
            isPermissions: dataPermissions(`M24_${ this.$route.meta.policyType }-View`, "newPolicyNo"),
          },
          {
            label: "所属客户",
            prop: "bindUserName",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入",
            isPermissions: dataPermissions(`M24_${ this.$route.meta.policyType }-View`, "bindUser"),
          },
        ],
      },
      column: [
        {
          label: "序号",
          type: "index",
          isShow: true,
          fixed: "left",
        },
        {
          label: "上一年保单号",
          prop: "lastPolicyNo",
          width: 180,
          isShow: true,
          isSlot: true,
          width: 220,
          isPermissions: dataPermissions(`M24_${ this.$route.meta.policyType }-View`, "lastPolicyNo")
        },
        {
          label: "投保人",
          prop: "playersHolder",
          width: 220,
          isShow: true,
          isPermissions: dataPermissions(`M24_${ this.$route.meta.policyType }-View`, "policyHolder")
        },
        {
          label: "被保险人",
          prop: "insured",
          isShow: true,
          width: 220,
          isPermissions: dataPermissions(`M24_${ this.$route.meta.policyType }-View`, "insured")
        },
        {
          label: "受益人",
          prop: "beneficiary",
          isShow: true,
          width: 220,
          isPermissions: dataPermissions(`M24_${ this.$route.meta.policyType }-View`, "beneficiary") && this.$route.meta.policyType == 1
        },
        {
          label: "设备类型",
          prop: "deviceTypeName",
          width: 200,
          isShow: true,
          isPermissions: dataPermissions(`M24_${ this.$route.meta.policyType }-View`, "frameNo") && this.$route.meta.policyType != 3
        },
        {
          label: "车架号",
          prop: "frameNo",
          width: 200,
          isShow: true,
          'show-overflow-tooltip': true,
          isSlot: true,
          isPermissions: dataPermissions(`M24_${ this.$route.meta.policyType }-View`, "frameNo") && this.$route.meta.policyType == 1
        },
        {
          label: "车牌号",
          prop: "plate",
          width: 200,
          isShow: true,
          'show-overflow-tooltip': true,
          isPermissions: dataPermissions(`M24_${ this.$route.meta.policyType }-View`, "plate") && this.$route.meta.policyType == 2
        },
        {
          label: "承保公司",
          prop: "insuranceCompany",
          width: 265,
          isShow: true,
          isPermissions: dataPermissions(`M24_${ this.$route.meta.policyType }-View`, "insuranceCompany")
        },
        {
          label: "分支机构",
          prop: "insuranceBranchName",
          width: 265,
          isShow: true,
          isPermissions: dataPermissions(`M24_${ this.$route.meta.policyType }-View`, "insuranceBranchName"),
        },
        {
          label: this.$route.meta.policyType !== 1 ? "险种类型" : "险种名称",
          prop: "insuranceName",
          width: 265,
          isShow: true,
          isPermissions: dataPermissions(`M24_${ this.$route.meta.policyType }-View`, "insuranceName")
        },
        //   {
        //     label: "承保方案名称",
        //     prop: "schemeName",
        //     width: 265,
        //     isShow: true,
        //     isPermissions: dataPermissions(`M24_${ this.$route.meta.policyType }-View`, "schemeName")
        //   },
        {
          label: "保险到期",
          prop: "endDate",
          isShow: true,
          width: 100,
          isPermissions: dataPermissions(`M24_${ this.$route.meta.policyType }-View`, "endDate")
        },
        {
          label: "总保费",
          prop: "totalPremium",
          isShow: true,
          isPermissions: dataPermissions(`M24_${ this.$route.meta.policyType }-View`, "totalPremium")
        },
        {
          label: "业务员",
          prop: "business",
          isShow: true,
          isPermissions: dataPermissions(`M24_${ this.$route.meta.policyType }-View`, "salesmanName")
        },
        {
          label: "续保状态",
          prop: "renewalState",
          isShow: true,
          isSlot: true,
          isPermissions: dataPermissions(`M24_${ this.$route.meta.policyType }-View`, "renewalState")
        },
        {
          label: "所属部门",
          prop: "salesmanDeptName",
          isShow: true,
          isPermissions: dataPermissions(`M24_${ this.$route.meta.policyType }-View`, "salesmanDeptName")
        },
        {
          label: "所属客户",
          prop: "bindUserName",
          isShow: true,
          isPermissions: dataPermissions(`M24_${ this.$route.meta.policyType }-View`, "bindUser")
        },
        {
          label: "项目名称",
          prop: "projectName",
          isShow: true,
          isPermissions: dataPermissions(`M24_${ this.$route.meta.policyType }-View`, "projectName") && this.$route.meta.policyType != 2
        },
        {
          label: "业务渠道",
          prop: "sourceChannel",
          align: "center",
          isShow: true,
          isPermissions: dataPermissions(`M24_${ this.$route.meta.policyType }-View`, "sourceChannel")
        },
        //   {
        //     label: "所属项目",
        //     prop: "projectName",
        //     isShow: true,
        //     isPermissions: dataPermissions(`M24_${ this.$route.meta.policyType }-View`, "renewalState") && this.$route.meta.policyType != 1
        //   },
        {
          label: "新保单号",
          prop: "policyNo",
          isShow: true,
          isSlot: true,
          width: 240,
          isPermissions: dataPermissions(`M24_${ this.$route.meta.policyType }-View`, "newPolicyNo")
        },
        {
          label: "新询价单号",
          prop: "newEnquiryNo",
          isSlot: true,
          isShow: true,
          width: 240,
          isPermissions: dataPermissions(`M24_${ this.$route.meta.policyType }-View`, "newEnquiryNo")
        },
        {
          label: "操作",
          prop: "operate",
          isShow: true,
          width: 140,
          fixed: "right",
          isSlot: true,
        },
      ],
      isFast: false,
      dataList: [],
      oldData: {},
      statisticsList: [
        {
          name: '应续保单总数',
          value: 0
        },
        {
          name: '应续总保费',
          value: 0
        },
        {
          name: '已续保单总数',
          value: 0
        },
        {
          name: '已续总保费',
          value: 0
        },
        {
          name: '未续保单总数',
          value: 0
        },
        {
          name: '未续总保费',
          value: 0
        },
        {
          name: '件数续保率',
          value: 0,
          unit: '%'
        },
        {
          name: '保费续保率',
          value: 0,
          unit: '%'
        }
      ],
      statisticsLoading: false
    };
  },
  created() {
    if (this.userInfo.loginIdentity == 'agent') {
      this.handleColumn('所属部门');
      if (this.$route.meta.policyType !== 2) this.handleColumn('项目名称');
      this.$forceUpdate();
    }
    dictionaryBatch({
      codes: "FCXInsCategory,CXInsCategory,projectName,CXInsProjectName,FCXInsProjectName,CXchannel,CXvehicleType,vehicleType",
    }).then((res) => {
      if (res && res.data) {
        // let ind2 = this.option.searchList.findIndex(
        //   (val) => val.label === "项目名称"
        // );
        // this.option.searchList[ind2].selectList = ((this.$route.meta.policyType == 1 ? res.data.projectName : this.$route.meta.policyType == 2 ? res.data.CXInsProjectName : res.data.FCXInsProjectName) || []).map(
        //   (val) => {
        //     return {
        //       text: val.dictValue,
        //       value: val.dictValue,
        //     };
        //   }
        // );
        // this.option.searchList[ind2].selectList.unshift({
        //   text: "全部",
        //   value: "",
        // });
        if (this.$route.meta.policyType != 1) {
          let ind = this.option.searchList.findIndex(
            (val) => val.label === "险种类型"
          );
          this.option.searchList[ind].selectList = this.$route.meta.policyType == 2 ? res.data.CXInsCategory.map(
            (val) => {
              return {
                text: val.dictValue,
                value: val.dictKey,
              };
            }
          ) : res.data.FCXInsCategory.map(
            (val) => {
              return {
                text: val.dictValue,
                value: val.dictKey,
              };
            }
          );
          this.option.searchList[ind].selectList.unshift({
            text: "全部",
            value: "",
          });
        }
        let index10 = this.option.searchList.findIndex(
          (val) => val.label === "业务渠道"
        );
        this.option.searchList[index10].selectList = res.data.CXchannel.map(
          (val) => {
            return {
              text: val.dictValue,
              value: val.dictValue,
            };
          }
        );
        if (this.$route.meta.policyType != 3) {
          let indv = this.option.searchList.findIndex(
            (val) => val.label === "设备类型"
          );
          this.option.searchList[indv].selectList = this.$route.meta.policyType == 2 ? res.data.CXvehicleType.map(
            (val) => {
              return {
                text: val.dictValue,
                value: val.dictValue,
              };
            }
          ) : res.data.vehicleType.map(
            (val) => {
              return {
                text: val.dictValue,
                value: val.dictValue,
              };
            }
          );
          this.option.searchList[indv].selectList.unshift({
            text: "全部",
            value: "",
          });
        }
      }
    });
    pageListToSelect({
        projectName: '',
        bladeUserId: this.$store.state.userInfo.MJUserId,
      }).then(res=>{
          let ind2 = this.option.searchList.findIndex(
            (val) => val.label === "项目名称"
          );
          this.option.searchList[ind2].selectList = res.data;
      })
  },
  computed: {
    ...mapState(['userInfo']),
  },
  methods: {
    handleCheck(item, type) {
      if (type == 2) {
        this.$router.push({
          path: `/routerPolicyManagement/${ this.$route.meta.policyRoute }/enquiryDetails/` + item.enquiryId,
        });
      } else {
        this.$router.push({
          path:
            `/routerPolicyManagement/${ this.$route.meta.policyRoute }/enquiryAdd?renewalId=` +
            item.id +
            "&renewalNo=" +
            item.policyNo,
        });
      }
    },
    navToPolicy(item) {
      this.$router.push({
        path: `/routerPolicyManagement/${ this.$route.meta.policyRoute }/policyDetails/` + item.id,
      });
    },
    handleColumn(label) {
      let ind = this.column.findIndex(
        (val) => val.label === label
      );
      this.column.splice(ind, 1);
    },
    handleSearch(data, pageNum, pageSize, searcType, isReset) {
      data.current = data.pageNum;
      data.size = data.pageSize;
      data.fuzzyQuery = data.keyword;
      // if (isReset == "refresh") {
      //   if (this.isFast) {
      //     this.getList2(this.oldData);
      //   } else {
      //     this.getList(this.oldData);
      //   }
      // } else if (isReset == "fuzzy") {
      //   this.isFast = true;
      //   this.oldData = JSON.parse(JSON.stringify(data));
      //   this.getList2(data);
      // } else {
      this.isFast = false;
      this.oldData = JSON.parse(JSON.stringify(data));
      this.getList(data);
      // }
    },
    getList(data) {
      this.statisticsLoading = true;
      if (data.projectNameList && data.projectNameList.length) {
        data.projectName = data.projectNameList.toString();
        delete data.projectNameList;
      }
      getRenewalDataStatistics(data).then(res => {
        this.statisticsLoading = false;
        this.statisticsList[0].value = res.data.total || 0;
        this.statisticsList[1].value = res.data.totalPremium || 0;
        this.statisticsList[2].value = res.data.renewalTotal || 0;
        this.statisticsList[3].value = res.data.renewalTotalPremium || 0;
        this.statisticsList[4].value = res.data.unRenewalTotal || 0;
        this.statisticsList[5].value = res.data.unRenewalTotalPremium || 0;
        this.statisticsList[6].value = res.data.timeRenewalRate || 0;
        this.statisticsList[7].value = res.data.premiumRenewalRate || 0;
      }).catch(err => {
        this.statisticsLoading = false;
      });
      renewalList(data).then((res) => {
        this.dataList = res.data.records;
        this.totalNum = res.data.total;
      });
    },
    getList2(data) {
      renewalListFast(data).then((res) => {
        this.dataList = res.data.records;
        this.totalNum = res.data.total;
      });
    },
    handleExport(data, obj) {
      // if (!data || !data.length) {
      //   this.$message.warning("请选择需要导出的询价单！");
      //   return false;
      // }
      let ids = data.map((val) => val.id).toString();
      obj.policyNos = ids;
      exportRenewalByIds(obj).then((res) => {
        if (res) {
          this.$message({
            message: "导出成功",
            type: "success",
          });
        }
      });
    },
    handleHasPerms(e) {
      return hasPerms(e);
    },
  },
};
</script>
<style lang="scss" scoped></style>
